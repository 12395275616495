
import { defineComponent, defineAsyncComponent } from 'vue'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import { UserRequestsInterface } from '@/shared/interfaces/user-requests.interface'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import router from '@/router'
import DriverMap from '@/views/account/workshifts/components/DriverMap.vue'
import { apiEndpoints } from '@/shared/endpoints/api'
import axiosInstance from '@/shared/utils/axiosInstance'

export default defineComponent({
  components: {
    DriverMap,
    OrderByArrow: defineAsyncComponent(() => import('@/shared/OrderByArrow.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  data () {
    return {
      exportLoading: false,
      columns: [
        {
          label: this.$t('table.driver'),
          field: 'driver'
        },
        {
          label: this.$t('table.createdAt'),
          field: 'createdAt'
        },
        {
          label: this.$t('table.clockIn'),
          field: 'clockIn'
        },
        {
          label: this.$t('table.clockOut'),
          field: 'clockOut'
        },
        {
          label: this.$t('table.totalWorkingHours'),
          field: 'totalWorkingHours'
        },
        {
          label: this.$t('table.clockInLocation'),
          field: 'clockInLocation'
        },
        {
          label: this.$t('table.clockOutLocation'),
          field: 'clockOutLocation'
        }
      ],
      data: [],
      showModal: false,
      showAssignModal: false,
      showClockOutConfirmation: false,
      selectedRequest: null as any,
      selectedVehicleId: 0,
      searchValue: '',
      current: 1,
      isAssigned: false,
      searchSurnameValue: '',
      searchNameValue: '',
      showFilterModal: false,
      locationObj: null as any,
      shortTable: true,
      height: 500,
      sortFields: [
        { id: 'created_at', currentSort: false, sort: null },
        { id: 'lastClockIn', currentSort: false, sort: null },
        { id: 'lastClockOut', currentSort: false, sort: null }
      ],
      filters: [
        {
          name: 'Is Clock In',
          model: 'isClockIn',
          type: 'radio',
          data: [
            { id: true, label: 'Yes' },
            { id: false, label: 'No' }
          ]
        },
        {
          name: 'Is Clock Out',
          model: 'isClockOut',
          type: 'radio',
          data: [
            { id: true, label: 'Yes' },
            { id: false, label: 'No' }
          ]
        },
        {
          name: 'Is Clock Out Approved',
          model: 'isClockOutApproved',
          type: 'radio',
          data: [
            { id: true, label: 'Yes' },
            { id: false, label: 'No' }
          ]
        },
        { name: 'Request Date', model: 'requestDate', type: 'date', calendarType: 'range' },
        {
          name: 'Darkstores',
          model: 'darkstoreIds',
          type: 'checkbox',
          data: []
        }
      ]
    }
  },
  computed: {
    userRequests (): UserRequestsInterface[] {
      return this.$store.getters['workShifts/getUserRequests']
    },
    userExportRequests (): UserRequestsInterface[] {
      return this.$store.getters['workShifts/getUserExportRequests']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['workShifts/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['workShifts/getRequestLoading']
    },
    darkstores (): DarkStoreInterface[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    }
  },
  mounted (): void {
    this.getDarkStores()
    this.height = document.documentElement.clientHeight - 270
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 270
    })
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchNameValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, name: this.searchNameValue } })
    },
    darkstores: {
      immediate: true,
      handler (val) {
        this.filters[4].data = val
      }
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'OvertimeRequests') {
          this.current = val.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          let params = JSON.parse(JSON.stringify(val.query))
          params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
          this.$store.dispatch(`workShifts/${WorkShiftActions.FETCH_USER_REQUESTS}`, params)
        }
      }
    }
  },
  methods: {
    getDarkStores (pageNumber = 1, pageSize = 100): void {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber, pageSize })
    },
    getUserRequests (): void {
      let params = JSON.parse(JSON.stringify(this.$route.query))
      params = { pageSize: params.pageSize ? params.pageSize : 10, pageNumber: params.pageNumber ? params.pageNumber : 1, ...params }
      this.$store.dispatch(`workShifts/${WorkShiftActions.FETCH_USER_REQUESTS}`, params)
    },
    approveOvertime (request: UserRequestsInterface): void {
      const payload = {
        is_overtime_approved: true,
        overtime_minutes: request.totalOvertimeMinutes
      }
      this.$store.dispatch(`workShifts/${WorkShiftActions.APPROVE_REQUEST}`, { data: payload, requestId: request.id }).then(() => {
        this.getUserRequests()
      })
    },
    startAnimation (): void {
      const shiftsContainer: any = this.$refs.shiftsContent
      const shiftMap: any = this.$refs.shiftMap
      if (this.shortTable) {
        shiftsContainer.classList.add('animate-to-end')
        shiftMap.classList.add('w-0')
        shiftMap.classList.remove('calculated-width')
      } else {
        shiftsContainer.classList.remove('animate-to-end')
        shiftMap.classList.add('calculated-width')
        shiftMap.classList.remove('w-0')
      }
    },
    handleClockOut (request: UserRequestsInterface): void {
      this.selectedRequest = request
      this.showClockOutConfirmation = true
    },
    approveClockout (type: 0 | 1): void {
      const payload = {
        is_clock_out_approved: type,
        driver_id: this.selectedRequest.driver.id
      }
      this.$store.dispatch(`workShifts/${WorkShiftActions.APPROVE_CLOCKOUT}`, { data: payload, requestId: this.selectedRequest.id }).then(() => {
        this.showClockOutConfirmation = false
        this.getUserRequests()
      }).catch(() => {
        this.showClockOutConfirmation = false
      })
    },
    clickSort (field: any, currentSortName: string): void {
      this.sortFields.map((el: any) => {
        if (el.id === currentSortName) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      const queries = this.$route.query
      router.push({ query: { ...queries, orderBy: field.id, sort: field.sort } })
    },
    showClockOutLocation (driverId: number, shiftId: number): void {
      axiosInstance({
        method: apiEndpoints.driver.clockOutLocation.method,
        url: apiEndpoints.driver.clockOutLocation.url + driverId,
        params: { shift_id: shiftId }
      }).then(resp => {
        if (resp?.data?.data && Object.keys(resp.data.data).length) {
          this.locationObj = {
            latitude: resp.data.data.current_driver_latitude,
            longitude: resp.data.data.current_driver_longitude
          }
        }
        if (!this.shortTable) {
          this.startAnimation()
          this.shortTable = true
        }
      }).catch(err => {
        throw new Error(err)
      })
    },
    showClockInLocation (driverId: number, shiftId: number): void {
      axiosInstance({
        method: apiEndpoints.driver.clockInLocation.method,
        url: apiEndpoints.driver.clockInLocation.url + driverId,
        params: { shift_id: shiftId }
      }).then(resp => {
        this.locationObj = {
          latitude: resp.data.data.current_driver_latitude,
          longitude: resp.data.data.current_driver_longitude
        }
        if (!this.shortTable) {
          this.startAnimation()
          this.shortTable = true
        }
      }).catch(err => {
        throw new Error(err)
      })
    },
    createDriverFullName (driver: any): string {
      if (driver.firstName && driver.lastName) {
        return `${driver.userName} - ${driver.firstName} ${driver.lastName}`
      } else {
        return `${driver.userName}`
      }
    },
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportLoading = true
      this.$store.dispatch(`workShifts/${WorkShiftActions.FETCH_USER_REQUESTS}`, {
        pageSize: this.pagination.totalItems || 100,
        pageNumber: 1,
        export: true,
        requestDate_from: this.$route.query?.requestDate_from ?? undefined,
        requestDate_to: this.$route.query.requestDate_to ?? undefined
      }).then((res: any) => {
        const button = document.getElementById('excel-export')
        this.data = res.data.map((request: any) => {
          return {
            driver: this.createDriverFullName(request.driver.driverInfo),
            createdAt: request.createdAt,
            clockIn: request.clockInAt ? request.clockInAt : '-',
            clockOut: request.clockOutAt ? request.clockOutAt : '-',
            totalWorkingHours: (request.totalWorkingMinutes[0] || request.totalWorkingMinutes[1])
              ? (request.totalWorkingMinutes[0] ? request.totalWorkingMinutes[0] + ' ' + this.$t('hourShort') + ' ' : '') + (request.totalWorkingMinutes[1] ? request.totalWorkingMinutes[1] + ' ' + this.$t('minuteShort') : '')
              : '-',
            clockInLocation: request.clockInLocation ? request.clockInLocation.latitude + ' ' + request.clockInLocation.longitude : '-',
            clockOutLocation: request.clockOutLocation ? request.clockOutLocation.latitude + ' ' + request.clockOutLocation.longitude : '-'
          }
        })
        this.exportLoading = false
        if (button) {
          setTimeout(() => {
            button.click()
          }, 200)
        }
      })
    }
  }
})
